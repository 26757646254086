.start-section {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 11vw 13vw;
	background-image: url('../../../../img/PSRP_logo.png');
	background-repeat: no-repeat;
	background-position: 50% 60%;
	background-size: 50%;
	background-color: white;

	&__info-wrapper {
		display: flex;
		font-weight: 600;
		text-align: justify;
		font-size: calc(1em + 0.7vw);
		line-height: 1.3em;
		color: black;
	}
}
