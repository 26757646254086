.task-section {
	// height: 100%;
	display: flex;
	padding: 2vw 6vw;
	background-color: white;
	justify-content: center;
	flex-direction: column;

	.task-slide {
		height: 100%;
		img {
			max-height: 400px;
			object-fit: contain;
		}
	}

	.carousel-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.carousel-root {
			.carousel {
				display: flex;
				justify-content: center;
			}
		}
	}
	.task-selector {
		display: flex;
		justify-content: center;
		flex-direction: row;
		.combo-selector {
			padding: 20px;
			width: 300px;
		}
	}
}
