.next-task-section {
	height: 100%;
	display: flex;
	padding: 11vw 3vw;
	background-color: white;
}

.image-text-container {
	width: 100%;
	//height: 100vh;
	background: url('../../../../assets/images/background-blured.png') 50% 0
		no-repeat;
	background-size: cover;
}

.image-text-h {
	margin: 0;
	font-size: 20vw;
	font-weight: 900;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 97%;
	color: black;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	background: white;
	mix-blend-mode: screen;
}
