.navbar {
	background-color: black;
}
.MuiLink-underlineHover {
	&:hover {
		text-decoration: none !important;
	}
}
.navbar-logo {
	height: 40px;

	&:hover {
		opacity: 0.7;
	}

	&:active {
		opacity: 0.5;
	}
}
