.previous-task-section {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0vw 3vw;
	background-color: white;

	.date-info-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
	}

	.image-text-container {
		flex-grow: 1;
		background: url('../../../../assets/images/background-blured.png') 50% 0
			no-repeat;
		background-size: cover;
	}

	.image-text-h {
		margin: 0;
		font-size: 298px;
		font-weight: 900;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		color: black;
		text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
		background: white;
		mix-blend-mode: screen;
	}

	.vertical-text {
		font-size: 68px;
		font-weight: 600;
		color: black;
		bottom: 0;
	}

	.task-description {
		font-size: 19px;
		font-weight: 600;
		color: black;
		text-align: justify;
		white-space: pre-wrap;
		display: flex;
		align-items: center;
		width: 60%;
		height: 370px;
		margin-top: 60px;
	}

	@media screen and (max-width: 900px) {
		.task-description {
			width: 95%;
		}
	}

	.date-picker-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 5%;
		padding-bottom: 5%;
	}

	.date-picker-input {
		width: 30%;
	}
}
