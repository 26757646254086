.section-navigator {
	position: fixed;
	height: 30%;
	width: 5%;
	right: 0;
	top: 40%;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;

	&__dot {
		height: 1.5em;
		width: 1.5em;
		background-color: #ffde2a;
		border-radius: 50%;
	}

	&__img {
		height: 2.5em;
		width: 2.5em;
	}

	@media screen and (max-width: 900px) {
		display: none;
	}

	@media screen and (min-width: 900px) {
		display: flex;
	}
}
