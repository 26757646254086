.section-title {
	height: 27vh;
	max-width: 100%;
	background: transparent;
	font-weight: 600;
	font-size: calc(1em + 0.7vw);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	&__headline {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		text-align: center;
		margin: 0;
		line-height: calc(1em + 0.7vw);
		font-size: calc(2em + 0.7vw);
		font-weight: 600;
	}
}
