.contact-section {
	height: 100%;
	display: flex;
	padding: 8vw 3vw;
	background-color: white;

	.logo {
		margin: 7px;
		width: 52px;
		height: 52px;
	}

	.contact-info {
		text-align: center;
		font-size: calc(1.6em + 0.7vw);
		line-height: 40px;
		display: flex;
		margin: 0 20vw;
		font-weight: 500;
	}

	.contact-info-phone {
		text-align: center;
		font-size: calc(0.5em + 0.7vw);
		line-height: 40px;
		margin: 0 5vw;
		font-weight: 500;
	}

	.grid-container {
		height: 300px;
		color: #1a4387;
	}
}
