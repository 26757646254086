.main-page-container {
	margin-top: 100px;
}

html {
	background-image: url('../../../assets/images/background-blured.png');
	background-position: top center;
	padding: 0;
	background-color: black;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

.leaflet-popup-content-wrapper {
	background: #000 !important;
}

.leaflet-container a.leaflet-popup-close-button {
	color: #ffdf2f !important;
}

.leaflet-popup-tip {
	background: #000 !important;
}
